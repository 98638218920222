import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { UtilsService } from '@gci/utils';
import { AuthService } from 'app/core/auth/auth.service';
import { UserService } from 'app/core/user/user.service';
import { CreateGroupRequest, IGroup, IGroupForm, UpdateGroupRequest } from 'app/models/group.type';
import { environment } from 'environments/environment';
import { BehaviorSubject, catchError, map, Observable, of, switchMap, tap, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GroupService {
    private baseUrl = environment.apiBaseUrl;
    private _allgroup: BehaviorSubject<IGroup[]> = new BehaviorSubject<IGroup[]>(
        []
    );
    
    private _activegroup: BehaviorSubject<IGroup | null> =
        new BehaviorSubject<IGroup | null>(null);

    constructor(
        private _httpClient: HttpClient,
    ) { };

    get allgroup$(): Observable<IGroup[]> {
        return this._allgroup.asObservable();
    };

    set allgroup(data: IGroup[]) {
        console.log('ada yang set group', data)
        this._allgroup.next(data);
    };

    get activegroup$(): Observable<IGroup | null> {
        return this._activegroup.asObservable();
    };

    get activegroupid(): string {
        return this._activegroup.getValue()?.id || '';
    };

    set activegroup(id: string) {
        let allgroup = this._allgroup.getValue()
        let findGroup = allgroup.filter(group => group.id === id)
        this._activegroup.next(findGroup.length ? findGroup[0] : null);
    };
    

    /**
     * getGroup
     * 
     * This method will fetch group data and have 
     * all data saved in _allgroup
     * the first data saved in _activedata
     *  
     */
    getGroup(): Observable<IGroup[]> {
        let activeGroup = this._activegroup.getValue()
        if (activeGroup) {
            return of(this._allgroup.getValue())
        }
        
        return this._httpClient.get<{message: IGroup[]}>(`${this.baseUrl}group-service/group?search=&page=1&limit=100`).pipe(
            map((response) => response.message),
            tap((allgroup) => {
                this._allgroup.next(allgroup);
                allgroup.length && this._activegroup.next(allgroup[0]);
            }),
            catchError(err => {
                console.error('Error occurred: ', err);
                return throwError(() => new Error(err.error.message));
            })
        );
    };

    /**
     * createGroup
     * 
     * Create a new group.
     * Make sure you need at least an alias and solution before calling the method.
     * The alias must be unique.
     * The description and notes input is optional.
     * @param body
     * @returns 
     */
    createGroup(body: CreateGroupRequest): Observable<IGroup> {
        let newGroup: IGroupForm = {
            alias: body.alias,
            solution: body.solution,
            description: body.description,
            notes: body.notes,
        };
        return this._httpClient.post<{message: IGroup}>(`${this.baseUrl}group-service/group`, newGroup).pipe(
            map((response) => response.message),
            tap((newGroup) => {
                let allgroup: IGroup[] = this._allgroup.getValue();
                const updatedGroup: IGroup[] = [
                ...allgroup,
                newGroup
                ];
                this._activegroup.next(newGroup);
                this._allgroup.next(updatedGroup);
                return of(updatedGroup);
            }),
            catchError(err => {
                console.error('Error occurred: ', err);
                return throwError(() => new Error(err.error.message));
            })
        );
    }

    /**
     * joinGroup
     * 
     * Let the user join other user's group.
     * Currently this method doesn't work.
     * @param group_id 
     * @param user_id 
     * @returns 
     */
    joinGroup(group_id: string, user_id: string): Observable<IGroup> {
        const joinGroup = {
            group_id,
            user_id: user_id,
            // "role_id": "f35fd007-0fe9-48ea-9e69-d5fa47863f43",
        };

        return this._httpClient.post<{message: IGroup}>(`${this.baseUrl}group-service/user-group/join`, joinGroup).pipe(
            map((response) => response.message),
            tap((newGroup) => {
                let allgroup: IGroup[] = this._allgroup.getValue();
                const updatedGroup: IGroup[] = [
                ...allgroup,
                newGroup
                ];
                this._activegroup.next(newGroup);
                this._allgroup.next(updatedGroup);
                return of(updatedGroup);
            }),
            catchError(err => {
                console.error('Error occurred: ', err);
                return throwError(() => new Error(err.error.message));
            })
        );

        // let allgroup: IGroup[] = this._allgroup.getValue();
        // let finding_from_mockupgroup = this.mockupgroup.filter(i => i.id === group_id)
        // if (finding_from_mockupgroup.length === 0) {
        //     return throwError(() => new Error('Group not found'));
        // } else if (allgroup.map(i => i.id).includes(group_id)) {
        //     return throwError(() => new Error('Already join group'));
        // } else {
        //   let newGroup = finding_from_mockupgroup[0];
        //   const updatedGroup: IGroup[] = [...allgroup, newGroup];
        //   this._activegroup.next(newGroup);
        //   this._allgroup.next(updatedGroup);
        //   return of(updatedGroup);
        // }
    }

    updateGroup(body: UpdateGroupRequest): Observable<IGroup>{

        const selectedGroup = this._activegroup.getValue();

        return this._httpClient.put<{message: IGroup}>(`${this.baseUrl}group-service/group/${selectedGroup?.id}`, body).pipe(
            map(response => response.message),
            tap(updatedGroup => {
                let allGroup: IGroup[] = this._allgroup.getValue();
                allGroup.forEach((el, index) => {
                    if (el.id === updatedGroup.id) {
                        let newData = { ...allGroup[index], ...updatedGroup }
                        allGroup[index] = newData;
                        this._activegroup.next(newData);
                    }
                })
                this._allgroup.next(allGroup);
            }),
            catchError(err => {
                console.error('Error occurred: ', err);
                return throwError(() => new Error(err.error.message));
            })
        )
    }

    deleteGroup(): Observable<IGroup> {
        
        const selectedGroup = this._activegroup.getValue();
        
        return this._httpClient.delete<{message: IGroup}>(`${this.baseUrl}group-service/group/${selectedGroup?.id}`).pipe(
            map(response => response.message),
            tap(deletedGroup => {
                let allGroup = this._allgroup.getValue();
                let activeGroup = this._activegroup.getValue();

                allGroup = allGroup.filter(el => el.id !== deletedGroup.id);
                
                this._allgroup.next(allGroup);
                if(activeGroup){
                    if(activeGroup.id === deletedGroup.id){
                        this._activegroup.next(allGroup[0]);
                    }
                }
            }),
            catchError(err => {
                console.error('Error occurred: ', err);
                return throwError(() => new Error(err.error.message));
            })
        )
    }
    
    clearGroup() {
        this._allgroup.next([]);
        this._activegroup.next(null);
    }
}